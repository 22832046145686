#UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and !ltIE6
  IE8 = IE and ltIE8 and !ltIE7 and !ltIE6
  IE9 = IE and ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  IE10 = IE and !ltIE9 and !ltIE8 and !ltIE7 and !ltIE6
  Webkit = !document.uniqueID and !window.opera and !window.sidebar and !window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

#URL
url = do ->
  href = location.href.split '/'

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val == '' or i == href.length - 1 and val.indexOf '.'
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j == 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

$ ->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  #フッター高さ取得
  footerHeight = # {{{
    ->
      add = 80
      height = $('#footer').outerHeight()
      $('#content').css('padding-bottom',height+add)
      $('#footer').css('height',height)
      return

  $(window).on('resize load'
    ->
      footerHeight()
      return
  )
  # }}}

  # アンカーリンク
  $('a[href^=#]').click(#{{{
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate(
        scrollTop:$($(this).attr('href')).offset().top - 20
      ,
        easing:'easeInOutCirc',
        duration:1000
      )

      e.preventDefault()
      return
  )#}}}

  # ヘッダーグローバルメニュー
  $(".nav .nav-list .nav-item").mouseover(->
    firstSrc = $(this).find("img").attr("src")
    onSrc = $(this).find("img").attr("src").replace(".png", "_h.png")
    $(this).find("img").attr "src", onSrc  if firstSrc.indexOf("_a.png") is -1
    return
  ).mouseout ->
    firstSrc = $(this).find("img").attr("src")
    offSrc = $(this).find("img").attr("src").replace("_h.png", ".png")
    $(this).find("img").attr "src", offSrc  if firstSrc.indexOf("_a.png") is -1
    return

  # ヘッダーサブメニュー
  $('#gnavi .example, #gnavi .cause, #gnavi .modification').on('mouseenter'#{{{
    ->
      $(this).addClass('is-hover')
  ).on('mouseleave'
    ->
      $(this).removeClass('is-hover')
  )#}}}


  # 左メニュー開閉
  $('#sub .menu-item').each ->
    if $(this).hasClass('is-open')
      $(this).find('.page-list').css("display","block")

  $('#sub .menu-item .title').on('click'
    (e)->
        $(this).closest('.menu-item').toggleClass('is-open')
        $(this).closest('.menu-item').find('.page-list').slideToggle(150)
        e.stopPropagation()
  )

  $('#sub .menu-item .title a').on('click'
    (e)->
      e.stopPropagation()
  )

 # Q&A開閉
  $('#main .qa-box .q-title').on('click'
    (e)->
      $(this).closest('.qa-inner').toggleClass('is-open')
      $(this).closest('.qa-inner').find('.answer-box').slideToggle(150)
      e.stopPropagation()
  )

  $('#main .qa-box .q-title').on('click'
    (e)->
      e.stopPropagation()
  )

#グロナビ追従
  if _ua.Mobile isnt 0
    if $class.indexOf("form") is -1
      $(window).load ->
        nav = $("#gnavi")
        navlist = $("#gnavi .nav-list")
        navHeight = nav.height()
        if $class is "home"
          headerHeight = 420 - navHeight
        else
          headerHeight = $("#header").height() - navHeight
        showFlag = false
        $(window).scroll ->
          nowPos = $(this).scrollTop()
          if nowPos > headerHeight
            if showFlag is false
              showFlag = true
              nav.css
                width: "100%"
                display: "block"
                position: "fixed"
                top: "0"
                left: "0"
                zIndex: "1000"
              navlist.css
                margin: "0 auto"
              if $class isnt "home"
                $("#content").css
                  paddingTop: "67px"

          else if nowPos <= headerHeight
            if showFlag
              showFlag = false
              nav.css position: "relative"
              if $class is "home"
                navlist.css
                  margin: "-67px auto 0"
              else
                navlist.css
                  margin: "0 auto"
              if $class isnt "home"
                $("#content").css
                  paddingTop: "0"
          return

#問い合わせライトナビ
  if $class.indexOf("form") is -1
    $ ->
      $(".sidebar .sd-pagetop img").mouseover(->
        firstSrc = $(this).attr("src")
        onSrc = $(this).attr("src").replace(".png", "_h.png")
        $(this).attr("src", onSrc)
        return
      ).mouseout ->
        firstSrc = $(this).attr("src")
        offSrc = $(this).attr("src").replace("_h.png", ".png")
        $(this).attr("src", offSrc)
        return
      sidebar = $(".sidebar")
      sidebar.hide()
      $(window).scroll ->
        if $(this).scrollTop() > 200
          sidebar.fadeIn()
        else
          sidebar.fadeOut()
        return

      return







































